import helperUtil from 'util'
import authenticationService from './authentication.service'
import dateFnsFormat from 'date-fns/format';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SHUL = process.env.REACT_APP_SHUL;

export const donationService = {
    getDonations
};

function formatDate(date) {
    const format = 'MM-dd-yyyy';
    return dateFnsFormat(date, format);
}

async function getDonations(startDate, endDate) {
    let clientId = authenticationService.getUserID();
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    let donations = undefined;
    await fetch(SERVER_URL + 'api/ContactDonations/getDonations/' + clientId + '/' + startDate + "/" + endDate + "/" + SHUL)
    .then(helperUtil.handleErrors)
    .then(function(response){
        return response.json();
    }).then(function(data) {
        console.log(data);
        donations = data;
    })
    .catch(function(error) {
        console.log(error);
    });

    return donations;
}


export default donationService