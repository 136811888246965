import './App.css';
import { Routes, Route } from 'react-router-dom';
import BootstrapNavbar from './components/BootstrapNavbar'
import Home from './components/pages/Home';
import PayPal from './components/pages/PayPal';
import PayPalConfirm from './components/pages/PayPalConfirm';
import RegistrationConfirmationComponent from './components/authentication/RegistrationConfirm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './components/authentication/Register';
import LoginComponent from './components/authentication/Login';
import ForgotCredentials from './components/authentication/ForgotCredentials';
import Activate from './components/authentication/Activate';
import UserDonations from './components/pages/content/UserDonations';
import { useState } from 'react';
import PasswordReset from './components/authentication/PasswordReset';
import PasswordResetWasSent from './components/authentication/PasswordResetWasSent';
import ContactUsComponent from './components/pages/ContactUs';
import HelpComponent from './components/pages/Help';
import ContactUsSuccess from './components/pages/ContactUsSuccess';
import MenageRoles from './components/pages/admin/MenageRoles';
import UsersInRole from './components/pages/admin/UsersInRole';
import MenageProjects from './components/pages/admin/Projects/MenageProjects';
import Projects from './components/pages/content/Projects';
import MenageUserProjects from './components/pages/admin/Projects/MenageUserProjects';
import ContractorProjects from './components/pages/admin/Projects/MenageContractorProjects';
import Scheduler from './components/pages/admin/Schedule/Scheduler';
import Account from './components/pages/Account';
import TestIPN from './components/pages/admin/TestIPN';
import ShulSchedule from './components/pages/content/ShulSchedule';
import HomePagePost from './components/pages/ads/HomePagePost';
import HomepageAds from './components/pages/admin/ads/HomepageAds';
import ClassifiedPostHome from './components/pages/ads/ClassifiedPostHome';
import ClassifiedPosted from './components/pages/ads/ClassifiedPosted';
import ConfirmClassifiedAd from './components/pages/ads/ConfirmClassifiedAd';
import ClassifiedAdsAdmin from './components/pages/admin/ads/ClassifiedAdsAdmin';
import ClassifiedRemoveSuccess from './components/pages/ads/ClassifiedRemoveSuccess';
import ClassifiedsMainViewer from './components/pages/ads/ClassifiedsMainViewer';
import ClassifiedsAdViewer from './components/pages/ads/ClassifiedsAdViewer';
import HomePagePosted from './components/pages/ads/HomePagePosted';
import ConfirmMainPagePost from './components/pages/ads/ConfirmMainPagePost';
import ConfigVariables from './components/pages/admin/variables/ConfigVariables';

function App() {

  const routesMap = { register: "/register", 
  registerConfirmation: "/registerConfirmation", 
  activateAccount: "/activateAccount",
  home: "/",
  login: "/login",
  userDonations: '/userDonations',
  forgotCredentials: '/forgotCredentials',
  passwordReset: '/passwordReset',
  passwordResetWasSent: '/passwordResetWasSent',
  contactUs: '/contactUs',
  help: '/help',
  contactUsSuccess: '/contactUsSuccess',
  menageRoles: "/menageRoles",
  usersInRole: "/usersInRole",
  menageProjects: "/menageProjects",
  projects: "/projects",
  menageUserProjects: "/menageUserProjects",
  contractorProjects: "/contractorProjects",
  account: "/account",
  testIPN: "/testIPN",
  scheduler: "/scheduler",
  shulSchedule: "/shulSchedule",
  paypal: "/paypal",
  payPalConfirm: "/payPalConfirm",
  homepagepost: "/homepagepost/",
  homepageAds: "/homepageAds",
  classifiedPostHome: "/classifiedPostHome/",
  classifiedPostHomeLcl: "/classifiedPostHome",
  classifiedPosted: "/classifiedPosted",
  confirmClassifiedAd: "/confirmClassifiedAd/",
  classifiedAdsAdmin: "/classifiedAdsAdmin",
  classifiedRemoveSuccess: "/classifiedRemoveSuccess",
  classifiedsMainViewer: "/classifiedsMainViewer",
  classifiedsAdViewer: "/classifiedsAdViewer",
  homePagePosted: "/homePagePosted",
  confirmMainPagePost: "/confirmMainPagePost/",
  configVariables: "/configVariables/"};
  

  const publicRoutes = [routesMap.register, 
    routesMap.login, 
    routesMap.registerConfirmation, 
    routesMap.activateAccount, 
    routesMap.home,
    routesMap.forgotCredentials,
    routesMap.passwordReset,
    routesMap.passwordResetWasSent,
    routesMap.contactUs,
    routesMap.help,
    routesMap.contactUsSuccess,
    routesMap.shulSchedule,
    routesMap.paypal,
    routesMap.homepagepost,
    routesMap.homepageAds,
    routesMap.classifiedPostHome,
    routesMap.classifiedPosted,
    routesMap.confirmClassifiedAd,
    routesMap.classifiedRemoveSuccess,
    routesMap.classifiedsMainViewer,
    routesMap.classifiedsAdViewer,
    routesMap.classifiedPostHomeLcl,
    routesMap.homePagePosted,
    routesMap.confirmMainPagePost,
    routesMap.payPalConfirm]

  const isPublicRouter = (router) => {
    return publicRoutes.indexOf(router) > -1;
  }

  const handler = (tokenParam) => {
    console.log('tokenHandler called ' + tokenParam);
    if(token === tokenParam){
      return;
    }
    
    setToken(tokenParam);
  }

  const [token, setToken] = useState(null);
  const tokenHandler = handler;
  const isPublicRouterHandler = isPublicRouter;

  return (
    <>
      <div className="app">
          <BootstrapNavbar tokenHandler={tokenHandler} isPublicRouter={isPublicRouterHandler}/>
          <Routes>
            <Route path={routesMap.home} element={<Home />} />
            <Route path={routesMap.register} element={<Register />} />
            <Route path={routesMap.registerConfirmation} element={<RegistrationConfirmationComponent />} />
            <Route path={routesMap.login} element={<LoginComponent />} />
            <Route path={routesMap.activateAccount} element={<Activate />} />
            <Route path={routesMap.userDonations} element={<UserDonations />} />
            <Route path={routesMap.forgotCredentials} element={<ForgotCredentials />} />
            <Route path={routesMap.passwordReset} element={<PasswordReset />} />
            <Route path={routesMap.passwordResetWasSent} element={<PasswordResetWasSent />} />
            <Route path={routesMap.contactUs} element={<ContactUsComponent />} />
            <Route path={routesMap.help} element={<HelpComponent />} />
            <Route path={routesMap.contactUsSuccess} element={<ContactUsSuccess />} />
            <Route path={routesMap.menageRoles} element={<MenageRoles />} />
            <Route path={routesMap.usersInRole} element={<UsersInRole />} />
            <Route path={routesMap.menageProjects} element={<MenageProjects />} />
            <Route path={routesMap.projects} element={<Projects />} /> 
            <Route path={routesMap.menageUserProjects} element={<MenageUserProjects />} />             
            <Route path={routesMap.contractorProjects} element={<ContractorProjects />} />
            <Route path={routesMap.account} element={<Account />} />
            <Route path={routesMap.testIPN} element={<TestIPN />} />
            <Route path={routesMap.scheduler} element={<Scheduler />} />
            <Route path={routesMap.shulSchedule} element={<ShulSchedule />} />
            <Route path={routesMap.paypal} element={<PayPal />} />
            <Route path={routesMap.homepagepost} element={<HomePagePost />} />
            <Route path={routesMap.homepageAds} element={<HomepageAds />} />
            <Route path={routesMap.classifiedPostHome} element={<ClassifiedPostHome />} />
            <Route path={routesMap.classifiedPostHomeLcl} element={<ClassifiedPostHome />} />
            <Route path={routesMap.classifiedPosted} element={<ClassifiedPosted />} />
            <Route path={routesMap.confirmClassifiedAd} element={<ConfirmClassifiedAd />} />
            <Route path={routesMap.classifiedAdsAdmin} element={<ClassifiedAdsAdmin />} />
            <Route path={routesMap.classifiedRemoveSuccess} element={<ClassifiedRemoveSuccess />} />
            <Route path={routesMap.classifiedsMainViewer} element={<ClassifiedsMainViewer />} />
            <Route path={routesMap.classifiedsAdViewer} element={<ClassifiedsAdViewer />} />
            <Route path={routesMap.homePagePosted} element={<HomePagePosted />} />
            <Route path={routesMap.confirmMainPagePost} element={<ConfirmMainPagePost />} />            
            <Route path={routesMap.payPalConfirm} element={<PayPalConfirm />} />
            <Route path={routesMap.configVariables} element={<ConfigVariables />} />
          </Routes>
      </div>    
    </>
  );
}

export default App;